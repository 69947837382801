export async function getMetadata () {
  let data = {
    deviceType: '', // 设备类型
    browserInfo: '', // 浏览器信息
    systemVersion: '', // 获取手机系统版本
    resolution: '', // 屏幕尺寸
    resolutions: '', // 屏幕分辨率
    network: '', // 网络类型
    operatingSystem: '', // 操作系统
    deviceLanguage: '', // 设备语言
    // eslint-disable-next-line no-tabs
    supportTechnology: '',	 //	是否支持某项技术（例如 WebSockets）
    // eslint-disable-next-line no-tabs
    batteryStatus: '', //	电池状态
    // eslint-disable-next-line no-tabs
    uniqueIdentifier: '',	//	唯一标识符
    // eslint-disable-next-line no-tabs
    screenOrientation: '',	//	屏幕方向
    // eslint-disable-next-line no-tabs
    supportVibration: '',	//	是否支持震动
    // eslint-disable-next-line no-tabs
    cpuCores: '',	//	CPU核数
    // eslint-disable-next-line no-tabs
    deviceLocation: '',	//	获取设备位置
    // eslint-disable-next-line no-tabs
    cameraInfo: '',	//	设备摄像头和
    microphoneInfo: '', // 麦克风信息
    // eslint-disable-next-line no-tabs
    deviceMemory: '',	//	设备内存
    // eslint-disable-next-line no-tabs
    storagespaceInfo: '',	//	设备存储空间信息
    // eslint-disable-next-line no-tabs
    usedstoragespaceInfo: '', 	//	设备使用空间
    supportSensors: '', // 设备是否支持传感器
    // eslint-disable-next-line no-tabs
    currentNetworkstatus: '',	//	当前网络状态
    cuttingboardInfo: '' // 获取剪切板信息
  }
  // 获取设备类型
  const deviceName = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|Windows|Macintosh)/)
  if (deviceName) {
    data.deviceType = deviceName[0]
  } else {
    // 未知设备
    data.deviceType = ''
  }

  // 获取浏览器信息
  const browserInfo = navigator.userAgent
  data.browserInfo = browserInfo

  // 获取手机系统版本
  const version = browserInfo.match(/iPhone OS (\d+_\d+(?:_\d+)?)/)
  if (version) {
    data.systemVersion = version[1]
  } else {
    data.systemVersion = ''
  }

  const { width, height } = window.screen
  // 获取屏幕尺寸
  data.resolution = width + 'x' + height
  // // 获取屏幕分辨率
  const devicePixelRatio = window.devicePixelRatio
  const screenWidth = width * devicePixelRatio
  const screenHeight = height * devicePixelRatio
  data.resolutions = screenWidth + 'x' + screenHeight

  // 获取网络类型
  if (navigator.connection) {
    const connectionType = navigator.connection.effectiveType
    if (connectionType) {
      data.network = connectionType
    } else {
      // 无法获取网络类型
      data.network = ''
    }
  } else {
    data.network = ''
  }

  // 获取设备操作系统
  const deviceOS = navigator.platform
  data.operatingSystem = deviceOS

  // 获取设备语言
  const deviceLanguage = navigator.language
  data.deviceLanguage = deviceLanguage

  // 是否支持某项技术（例如 WebSockets）
  const isWebSocketSupported = 'WebSocket' in window
  if (isWebSocketSupported) {
    data.supportTechnology = true
  } else {
    data.supportTechnology = false
  }
  // 设备电车状态
  const battery = navigator.battery || navigator.mozBattery || navigator.webkitBattery
  if (battery) {
    data.batteryStatus = battery.level * 100 + '%'
  } else {
    data.batteryStatus = ''
  }

  // 设备唯一标识符：
  const deviceID = window.localStorage.getItem('deviceID')
  if (!deviceID) {
    window.localStorage.setItem('deviceID', Math.random().toString(36).slice(2))
  }
  data.uniqueIdentifier = deviceID

  // 设备屏幕方向
  const screenOrientation = window.screen.orientation && window.screen.orientation.type
  data.screenOrientation = screenOrientation

  // 设备是否支持震动
  const isVibrationSupported = 'vibrate' in navigator
  if (isVibrationSupported) {
    data.supportVibration = true
  } else {
    data.supportVibration = false
  }
  // 设备CPU核数：
  const hardwareConcurrency = navigator.hardwareConcurrency
  data.cpuCores = hardwareConcurrency

  data.cameraInfo = ''
  // 设备内存
  const memory = navigator.deviceMemory
  if (memory) {
    data.deviceMemory = memory + 'GB'
  } else {
    data.deviceMemory = ''
  }

  // 设备存储空间信息：
  try {
    const storage = navigator.storage || navigator.webkitStorage
    if (storage) {
      const estimate = await storage.estimate()
      data.storagespaceInfo = estimate.quota / (1024 * 1024) + 'MB'
      data.usedstoragespaceInfo = estimate.usage / (1024 * 1024) + 'MB'
    } else {
      data.storagespaceInfo = ''
      data.usedstoragespaceInfo = ''
    }
  } catch (error) {
    data.storagespaceInfo = ''
    data.usedstoragespaceInfo = ''
  }

  // 设备是否支持传感器
  const sensor = window.Sensor || window.AbsoluteOrientationSensor || window.RelativeOrientationSensor
  if (sensor) {
    data.supportSensors = true
  } else {
    data.supportSensors = false
  }

  // 设备当前网络状态
  data.currentNetworkstatus = navigator.onLine

  // 获取剪切板信息
  try {
    const clipText = await navigator.clipboard.readText()
    data.cuttingboardInfo = clipText
  } catch (error) {
    data.cuttingboardInfo = ''
  }
  const abcdefgh = sessionStorage.getItem('abcdefgh')
  let obj = {}
  if (abcdefgh) {
    obj = JSON.parse(abcdefgh)
  }
  console.log(abcdefgh)
  data = { ...data, ...obj }
  console.log('获取设备信息方法执行完毕！！！！！！！！！！！')
  return data
}
